import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';

import Head from '../../../components/Head';
import ProductsDisplay from '../../../components/ProductsDisplay';
import { H5, Caption } from '../../../components/Typography';
import { ShopPageContents, ShopPageWrapper } from '../[collection]';

import { getCollectionProducts, isEvergreenCreator } from '../../../utils/collections';

import config from '../../../../config.json';
import { getCollectionByHandle } from '../../../api/collections';
import { getPropsForPageLoad } from '../../../utils/pageLoad';

const { pagination } = config;

const SearchQuery = styled(H5)`
  margin-bottom: 64px;
`;

type CreatorPageProps = {
  creatorCollection: Shopify.Collection;
  collectionHandle: string;
};

const CreatorPage: NextPage<CreatorPageProps> = ({ creatorCollection, collectionHandle }: CreatorPageProps) => {
  const [pageProducts, setPageProducts] = useState<Shopify.ProductEdge[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(creatorCollection.products.edges.length === pagination.products);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageProducts(creatorCollection.products.edges);
  }, [creatorCollection]);

  const loadMore = () => {
    setLoading(true);
    getCollectionByHandle(collectionHandle, pageProducts[pageProducts.length - 1].cursor, 1).then((collection) => {
      if (collection !== null) {
        if (collection?.products.edges.length < pagination.products) {
          setCanLoadMore(false);
        }
        setPageProducts(pageProducts.concat(collection?.products.edges));
      }
    });
  };

  return (
    <>
      <Head title={`${creatorCollection.title}`} url="/shop/creator" />
      <ShopPageWrapper>
        <div>
          <Caption>Campaigns By</Caption>
          <SearchQuery data-testid="creator-collection-title">{creatorCollection.title}</SearchQuery>
        </div>
        <ShopPageContents>
          <ProductsDisplay
            products={getCollectionProducts(pageProducts, false, isEvergreenCreator(collectionHandle))}
            loadMore={loadMore}
            canLoadMore={canLoadMore}
            loading={loading}
            titleForTracking={creatorCollection.title}
          />
        </ShopPageContents>
      </ShopPageWrapper>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<CreatorPageProps> = async (ctx) => {
  const { params } = ctx;
  const collectionHandle = params ? (params.handle as string) : '';

  const creatorCollection = await getCollectionByHandle(collectionHandle, undefined, 1);

  if (collectionHandle === '' || creatorCollection === null) {
    return {
      notFound: true,
    };
  }

  const propsForPageLoad = await getPropsForPageLoad(ctx);

  return {
    props: {
      creatorCollection,
      collectionHandle,
      ...propsForPageLoad,
    },
  };
};

export default CreatorPage;
